<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-app>
    <notification-snackbars-wrapper />
    <v-main class="main-content">
      <v-app-bar app flat class="v-bar--underline" color="background0" elevate-on-scroll>
        <router-link :to="{ name: 'chat' }" class="logo-link">
          <v-img src="/static/unstruct-logo-new.png" max-width="45" max-height="45" contain transition="scale-transition" />
        </router-link>
        <v-spacer />
        <v-slide-x-transition>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search IAOs ..."
            single-line
            hide-details
            clearable
            filled
            rounded
            dense
            class="search-field"
            @click:clear="clearSearch"
            @keyup.enter="fetchEntities"
          />
        </v-slide-x-transition>
        <v-spacer />
      </v-app-bar>

      <organization-banner />

      <v-container fluid class="pa-0">
        <v-fade-transition>
          <v-sheet v-if="loading || animationInProgress" class="loading-sheet" color="background0">
            <v-row justify="center" align="center" class="fill-height">
              <v-col cols="12" md="8" lg="6">
                <v-card class="mx-auto">
                  <v-card-title class="text-h5 justify-center">
                    UnStruct.AI is preparing your personalized experience...
                  </v-card-title>
                  <v-card-text>
                    <v-stepper v-model="currentStep" vertical>
                      <template v-for="(feature, index) in features">
                        <v-stepper-step
                          :key="`step-${index}`"
                          :step="index + 1"
                          :complete="currentStep > index + 1"
                        >
                          {{ feature.title }}
                        </v-stepper-step>

                        <v-stepper-content :key="`content-${index}`" :step="index + 1">
                          <v-card class="mb-4" outlined>
                            <v-card-text>
                              <p>{{ feature.description }}</p>
                              <v-skeleton-loader
                                :type="feature.skeletonType"
                                :height="feature.skeletonHeight"
                              ></v-skeleton-loader>
                            </v-card-text>
                          </v-card>
                        </v-stepper-content>
                      </template>
                    </v-stepper>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-fade-transition>

        <v-fade-transition>
          <incident-entities-tab v-if="!loading && !animationInProgress" :entities="entities" />
        </v-fade-transition>

        <v-slide-y-transition>
          <v-btn
            v-if="entities.length >= numItems"
            @click="fetchEntities(false)"
            :loading="loading"
            :disabled="loading"
            color="primary"
            rounded
            class="mt-5 mb-5 load-more-btn"
            elevation="2"
            block
          >
            <v-icon left>mdi-arrow-down</v-icon>
            Load More ({{ entities.length }} / {{ totalItems }})
          </v-btn>
        </v-slide-y-transition>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapMutations } from "vuex";
import NotificationSnackbarsWrapper from "@/components/NotificationSnackbarsWrapper.vue";
import OrganizationBanner from "@/organization/OrganizationBanner.vue";
import IncidentEntitiesTab from "@/entity/IncidentEntitiesTab.vue";
import EntityApi from "@/entity/api";
import SearchUtils from "@/search/utils";
import UniverseApi from "@/organization/api";
import userProjectsApi from '@/project/api';

export default {
  name: "EntityEntryPage",
  inject: ['updateTitle'],
  components: {
    OrganizationBanner,
    NotificationSnackbarsWrapper,
    IncidentEntitiesTab,
  },
  data() {
    return {
      universes: [],
      galaxies: [],
      search: "",
      lastSearchQuery: "",
      loading: false,
      items: [],
      selected: {},
      entities: [],
      currentPage: 1,
      totalPages: null,
      numItems: 10,
      totalItems: 0,
      itemsPerPageOptions: [10, 25, 50, 100],
      valueDeterminate1: 0,
      valueDeterminate2: 25,
      valueDeterminate3: 50,
      currentStep: 1,
      animationInProgress: false,
      animationInterval: null,
      features: [
        {
          title: "Entity-Centric Contextual Search",
          description: "Analyzing your query using our advanced ECCS technology...",
          skeletonType: "text-field, chip, chip, chip",
          skeletonHeight: 100
        },
        {
          title: "Active Data Object Network",
          description: "Building a network of relevant Interactive Data Objects (IAOs)...",
          skeletonType: "image",
          skeletonHeight: 200
        },
        {
          title: "Hyper-Contextual Insights",
          description: "Generating deep, personalized insights from diverse sources...",
          skeletonType: "list-item-three-line, list-item-three-line",
          skeletonHeight: 150
        },
        {
          title: "AI-Powered Knowledge Synthesis",
          description: "Synthesizing information to create novel connections...",
          skeletonType: "card-heading, image, article",
          skeletonHeight: 200
        },
        {
          title: "Personalized Knowledge Navigation",
          description: "Tailoring results to your unique context and interests...",
          skeletonType: "list-item-avatar-two-line, list-item-avatar-two-line",
          skeletonHeight: 150
        },
      ],
    };
  },
  computed: {
    ...mapFields("incident", ["selected.id"]),
    ...mapState("auth", ["currentUser", "currentProject", "currentOrganization"]),
    selectedUniverse: {
      get() {
        return this.currentOrganization;
      },
      set(value) {
        this.SET_CURRENT_ORGANIZATION(value);
      }
    },
    selectedGalaxy: {
      get() {
        return this.currentProject;
      },
      set(value) {
        this.SET_CURRENT_PROJECT(value);
      }
    },
  },
  mounted() {
    this.animateSteps();
    this.syncUrlWithStoredData();
    this.updateTitle("UnStruct AI - Entities");
  },
  created() {
    const savedProject = localStorage.getItem("project");
    if (savedProject) {
      this.selectedGalaxy = parseInt(savedProject);
    }
    this.search = this.$route.query.q || "";
    //this.fetchUniverses();
    //this.fetchGalaxies();
    this.fetchEntities(true);
  },
  watch: {
    $route(to, from) {
      this.syncUrlWithStoredData();
    }
  },
  methods: {
    ...mapMutations('auth', ['SET_CURRENT_PROJECT', 'SET_CURRENT_ORGANIZATION']),
    ...mapActions("auth", ["logout"]),
    syncUrlWithStoredData() {
      const storedUniverse = localStorage.getItem("organization");
      const currentPath = this.$route.path;
      const pathParts = currentPath.split('/').filter(Boolean);

      let needsRedirect = false;
      let newPath = '';

      // Check if the current universe matches the stored universe
      if (storedUniverse && (!pathParts[0] || pathParts[0] !== storedUniverse)) {
        needsRedirect = true;
        newPath = `/${storedUniverse}`;
      } else if (pathParts[0]) {
        newPath = `/${pathParts[0]}`;
      }

      // Always add '/ados' to the path
      newPath += '/ados';

      // Check if we need to redirect
      needsRedirect = needsRedirect || (currentPath !== newPath);

      if (needsRedirect) {
        // Use router.replace to update the URL without adding to browser history
        this.$router.replace(newPath);
      }
    },
    async fetchGalaxies() {
      try {
        const { data } = await userProjectsApi.getUserProjects();
        this.galaxies = data.items;
        const savedProject = localStorage.getItem("project");
        if (savedProject && this.galaxies.some(p => p.id === parseInt(savedProject))) {
          this.selectedGalaxy = parseInt(savedProject);
        } else if (this.galaxies && this.galaxies.length > 0) {
          this.selectedGalaxy = this.galaxies[0].id;
        }
      } catch (error) {
        console.error('Failed to fetch galaxies:', error);
      }
    },
    fetchUniverses() {
      UniverseApi.getAll()
        .then(response => {
          this.universes = response.data.items;
          const savedUniverse = localStorage.getItem("organization");
          if (savedUniverse && this.universes.some(u => u.slug === savedUniverse)) {
            this.selectedUniverse = savedUniverse;
          } else if (this.universes.length > 0) {
            this.selectedUniverse = this.universes[0].slug;
          }
        })
        .catch(error => {
          console.error("Error fetching universes:", error);
        });
    },
    onUniverseChange(slug) {
      // Set the new organization in localStorage
      localStorage.setItem("organization", slug);

      // Update the Vuex store
      this.$store.commit("auth/SET_CURRENT_ORGANIZATION", slug);

      // Get the current URL
      let currentUrl = new URL(window.location.href);

      // Split the pathname into parts
      let pathParts = currentUrl.pathname.split("/").filter(Boolean);

      // reset the project in local storage
      localStorage.removeItem("project");

      // The organization should be the first part of the path
      if (pathParts.length > 0) {
        pathParts[0] = slug;
      } else {
        pathParts.unshift(slug);
      }

      // Reconstruct the pathname
      currentUrl.pathname = "/" + pathParts.join("/");

      // Clear all existing query parameters
      currentUrl.searchParams.delete('q');
      currentUrl.search = "";

      // Update the URL and reload the page
      window.location.href = currentUrl.href;
    },
    onGalaxyChange(id) {
      // set item in local storage
      localStorage.setItem("project", id);
      // set the new project in the Vuex store
      this.$store.commit("auth/SET_CURRENT_PROJECT", id);
      // fetch entities for the new project
      this.selectedGalaxy = id;

      // reset the search query
      this.search = "";
      this.fetchEntities(true);
    },
    clearSearch() {
      this.search = "";
      if (this.lastSearchQuery) {
        // Only fetch entities if there was a previous search
        this.fetchEntities(true);
      }
      this.lastSearchQuery = "";
    },
    animateSteps() {
      this.animationInProgress = true;
      this.currentStep = 1;
      clearInterval(this.animationInterval);
      this.animationStartTime = Date.now();

      this.animationInterval = setInterval(() => {
        if (this.currentStep < this.features.length) {
          this.currentStep++;
        } else {
          clearInterval(this.animationInterval);
          this.animationInProgress = false;
        }
      }, 2000); // 2 seconds per step
    },
    fetchEntities(fromsearch = true) {
      // Reset the entities array if it's a new search
      if (fromsearch) {
        this.entities = [];
        this.currentPage = 1;
        this.animateSteps();
      }
      // update the url in the browser, don't reload the page, if q is not empty
      if (this.search) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set("q", this.search);
        window.history.pushState({}, "", newUrl);
        this.lastSearchQuery = this.search;
      } else {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("q");
        window.history.pushState({}, "", newUrl);
        this.lastSearchQuery = "";
      }
      this.loading = true;
      let filterOptions = {
        q: this.search,
        itemsPerPage: this.numItems,
        page: fromsearch ? 1 : this.currentPage,
        project_id: this.selectedGalaxy,
      };

      EntityApi.getAllByClass("incident", filterOptions)
        .then((response) => {
          if (fromsearch) {
            // Reset the entities array if it's a new search
            this.entities = [];
            this.entities = response.data.items;
            this.currentPage = 1;
            this.totalItems = response.data.total;
            // stop the animation
            this.stopAnimation();
          } else {
            this.entities.push(...response.data.items);
            this.currentPage++;
          }
        })
        .catch((error) => {
          console.error(error);
          this.stopAnimation();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    stopAnimation() {
      const minAnimationDuration = 2000; // 2 seconds minimum
      const animationEndTime = this.animationStartTime + minAnimationDuration;
      const remainingTime = Math.max(0, animationEndTime - Date.now());

      setTimeout(() => {
        clearInterval(this.animationInterval);
        this.animationInProgress = false;
      }, remainingTime);
    },
  },
};
</script>

<style scoped>
.logo-link {
  text-decoration: none;
  margin-top: 5px;
}

.search-field {
  max-width: 400px;
  transition: all 0.3s ease;
}

.search-field:focus-within {
  max-width: 600px;
}

.loading-sheet {
  min-height: 100vh;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.v-skeleton-loader {
  border-radius: 4px;
}

.v-skeleton-loader::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%);
  background-size: 1000px 100%;
}

.theme--light.v-skeleton-loader::after {
  background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
}

.load-more-btn {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 600px) {
  .search-field {
    max-width: 100%;
  }
}

.galaxy-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--v-primary-base);
}

</style>
